export const SMSToBookNumber = '1-877-874-5078';
export const CallToBookNumber = '1-866-468-2488';
export const MonToFriHours = '7AM-10PM MST Mon-Fri';
export const SatToSunHours = '9AM-9PM MST Sat-Sun';
export const EmailToBook = 'reservations@frontierlodging.com';
export const HotelUnavailableOnlineMessage =
  'This hotel is not available to book online, but may still have rooms. Please contact Frontier Lodging Solutions for booking options.';
export const LowestRateNotDisplayedMessage =
  'Lowest Rate for this hotel is not displayed due to policies that include NO CANCELLATION and NON REFUNDABLE';
export const tAndCsGenericText = `
  Hotel cancelation policy varies by hotel. Same day cancelations may result in fees or penalties. 
  Please refer to your itinerary for cancelation dates and times. If itinerary does not show cancelation policy, 
  contact Frontier Lodging Solutions immediately for more information. Frontier Lodging Solutions will not be responsible 
  for any costs associated with cancelations that don't adhere to the hotel's policy. If cancelation request is within 
  1 hour of cancelation deadline, traveler must call Frontier Lodging Solutions reservation team to process cancelation.
  `;

export const siteModeBusiness = 'Business';
export const siteModePersonal = 'Personal / LOA';
