import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Show,
  Spacer,
  Text
} from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import { NavLink } from 'react-router-dom';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import logo from 'src/assets/frontier-lodging-solutions-logo-v1.png';
import logosmall from 'src/assets/small-logo.svg';
import { ApplicationRoles } from 'src/constants/appRoles';
import { ApplicationRoutes } from 'src/constants/applicationRoutes';
import { FrontierUser } from 'src/globalModels/frontierUser';
import { SiteModeObject } from 'src/features/misc/HackyNavigationObject';
import { siteModeBusiness } from 'src/constants/strings';
import { SiteModeSelector } from './SiteModeSelector';

type NavLink = {
  text: string;
  url: string;
};

export const NavBar = () => {
  const isLocalhost = window.location.hostname == 'localhost';
  const auth = useAuth<FrontierUser>();
  const smo = JSON.parse(localStorage.getItem('smo')) as SiteModeObject;

  const userLinks = useMemo(() => {
    const toReturn = [
      {
        text: 'New booking',
        url: ApplicationRoutes.booking.hotelSearchLanding
      }
    ];
    if (
      auth.isAuthenticated &&
      (!auth.isInRole([ApplicationRoles.staff, ApplicationRoles.developer], false) || isLocalhost)
    ) {
      toReturn.push({
        text: smo && smo.currentMode == siteModeBusiness ? 'Profile management' : 'Your profile',
        url: ApplicationRoutes.account.profileManagement
      });
      toReturn.push({
        text: 'Your bookings',
        url: ApplicationRoutes.booking.bookingHistory
      });
    }
    return toReturn;
  }, [auth.isAuthenticated, auth.user, auth.user?.Roles]);

  const onLogoutClick = () => {
    auth.logout().then(() => {
      document.cookie = 'frontier.auth' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      window.location.href = '/';
    });
  };

  return (
    <>
      {/* Standard nav */}
      <Show above='md'>
        <Box
          bg={'white.100'}
          height={'70px'}
          marginBottom={'0px'}
          borderTopWidth={'10px'}
          borderTopColor={smo && smo.currentMode == siteModeBusiness ? 'orange.100' : 'blue.100'}
          borderStyle={'solid'}
          boxSizing='content-box'
          boxShadow={'none'}
          borderBottom={'1px solid rgb(221, 222, 224)'}>
          <Flex
            w={'100%'}
            h={'100%'}
            gap={'24px'}
            paddingEnd={'24px'}
            alignItems={'center'}
            justifyContent={'space-between'}>
            <Link href='/' paddingLeft={'20px'} paddingRight={'20px'}>
              <Image height={['40px']} src={logo} alt='Frontier Lodging Solutions' />
            </Link>
            {/* <ChakraBreakpoints isLocalHost={isLocalhost} /> */}
            <SiteModeSelector />
            {auth.isAuthenticated && (
              <>
                <Spacer />
                {userLinks.map((link) => (
                  <Link as={NavLink} to={link.url} key={link.text} reloadDocument={true}>
                    <strong>{link.text}</strong>
                  </Link>
                ))}
                <Spacer />
                <Link onClick={onLogoutClick} fontWeight={'bold'}>
                  Log out
                </Link>
              </>
            )}
          </Flex>
        </Box>
      </Show>

      {/* Mobile nav */}
      <Show below='md'>
        <Box
          bg={'white.100'}
          height={'50px'}
          marginBottom={'0px'}
          borderTopWidth={'5px'}
          borderTopColor={smo && smo.currentMode == siteModeBusiness ? 'orange.100' : 'blue.100'}
          borderStyle={'solid'}
          boxSizing='content-box'
          boxShadow={'none'}
          borderBottom={'1px solid rgb(221, 222, 224)'}>
          <Flex w={'100%'} h={'100%'} alignItems={'center'}>
            <Box paddingLeft={'8px'}>
              <Link href='/'>
                <Image height={['40px']} src={logosmall} alt='Frontier Lodging Solutions' />
              </Link>
            </Box>
            {/* <ChakraBreakpoints isLocalHost={isLocalhost} /> */}
            <Box marginLeft={'16px'}>
              <SiteModeSelector />
            </Box>
            {auth.isAuthenticated && (
              <>
                <Spacer />
                <Menu>
                  <MenuButton as={Button} rounded={'full'} variant={'primary'} cursor={'pointer'} minW={0}>
                    <Button
                      marginRight={'-8px'}
                      variant={'outline'}
                      border={'1px solid rgb(221, 222, 224)'}
                      leftIcon={<FontAwesomeIcon icon={faBars} />}
                      fontSize={'sm'}>
                      Menu
                    </Button>
                  </MenuButton>
                  <Portal>
                    <MenuList zIndex='popover'>
                      {userLinks.map((link) => (
                        <MenuItem as={NavLink} to={link.url} key={link.text}>
                          <strong>{link.text}</strong>
                        </MenuItem>
                      ))}
                      <MenuDivider />
                      <MenuItem onClick={onLogoutClick}>
                        <strong>Log out</strong>
                      </MenuItem>
                    </MenuList>
                  </Portal>
                </Menu>
              </>
            )}
          </Flex>
        </Box>
      </Show>
    </>
  );
};

type ChakraBreakpointsProps = {
  isLocalHost: boolean;
};

export const ChakraBreakpoints = (props: ChakraBreakpointsProps) => {
  if (props.isLocalHost === false) {
    return <></>;
  }
  return (
    <HStack>
      <Text hideBelow={'base'}>base</Text>
      <Text hideBelow={'sm'}>sm</Text>
      <Text hideBelow={'md'}>md</Text>
      <Text hideBelow={'lg'}>lg</Text>
      <Text hideBelow={'xl'}>xl</Text>
      <Text hideBelow={'2xl'}>2xl</Text>
    </HStack>
  );
};
