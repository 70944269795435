import { create } from 'zustand';
import { HotelSearchFilterModel } from '../hotelSearch/types/HotelSearch/HotelSearchFilterModel';
import { HotelSearchQuery } from '../hotelSearch/types/HotelSearch/HotelSearchQuery';

export type HotelBookingState = {
  hotelSearchQuery: HotelSearchQuery;
  hotelFilters: HotelSearchFilterModel;
  setSearchQuery: (query: HotelSearchQuery) => void;
  setHotelFilters: (filters: HotelSearchFilterModel) => void;
};

export const useHotelBookingStore = create<HotelBookingState>((set) => ({
  hotelSearchQuery: undefined,
  hotelFilters: undefined,
  setHotelFilters: (filters: HotelSearchFilterModel) => set({ hotelFilters: filters }),

  setSearchQuery: (query: HotelSearchQuery) => set({ hotelSearchQuery: query })
}));
